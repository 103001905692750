<template>
  <div>
    <ModalLockLocation
      v-if="showLockLocation"
      :showModal="showLockLocation"
      :onClickCancel="hideModal"
    />

    <ModalStopLocation
      v-if="showStopLocation"
      :showModal="showStopLocation"
      :onClickCancel="hideModal"
    />

    <ModalChangeStatus
      v-if="showChangeStatus"
      :showModal="showChangeStatus"
      :onClickCancel="hideModal"
    />

    <ModalDeactivateLocation
      v-if="showUnpublish"
      :showModal="showUnpublish"
      :location="location"
      :onClickCancel="hideModal"
      @locationDeactivated="unpublishLocation"
    />

    <div
      v-if="locationState.ActivationStatus === 'Neutral'"
      class="columns is-vcentered mb-6"
    >
      <div class="column">
        <Message :message="'Location is not activated'" :type="'is-light'" />
      </div>
      <div class="column is-one-fifth">
        <a @click="activateLocation" class="button is-light">Activate</a>
      </div>
    </div>

    <div
      v-if="locationState.ActivationStatus === 'Requested'"
      class="columns is-vcentered"
    >
      <div class="column">
        <Message
          :message="'Location has requested activation'"
          :type="'is-info'"
        />
      </div>
      <div class="column is-one-fifth">
        <a @click="activateLocation" class="button is-info">Activate</a>
      </div>
    </div>

    <div
      v-if="locationState.RequestActivation === 'Activated'"
      class="columns is-vcentered"
    >
      <div class="column">
        <Message :message="'Location is activated'" :type="'is-success'" />
      </div>
      <div class="column is-one-fifth">
        <a @click="deactivateLocation" class="button is-success">Deactivate</a>
      </div>
    </div>

    <div class="columns">
      <div class="column is-two-thirds pr-5">
        <div class="columns mb-6">
          <div class="column is-half">
            <div class="mb-6">
              <LocationLogo />
            </div>
            <div class="mb-6">
              <LocationData />
            </div>
            <div class="mb-6">
              <LocationLegal />
            </div>
            <div class="mb-6">
              <LocationSocial />
            </div>
          </div>
          <div class="column is-half">
            <div class="mb-6">
              <p class="title">Agreed to license</p>

              <Message
                v-if="!agreedToLicense"
                :message="'No license agreement!'"
                :type="'is-danger'"
              />

              <Message
                v-if="agreedToLicense && terms"
                :message="`Agreed to license ${terms.Version} on ${terms.Agreement.AgreedOn} by ${terms.Agreement.ProfileName}`"
                :type="'is-success'"
              />
            </div>

            <div class="mb-6">
              <p class="title">Active subscription</p>

              <Message
                v-if="activeSubscription"
                :message="
                  'Active subscription: ' + activeSubscription.PackageName
                "
                :type="'is-success'"
              />

              <Message
                v-if="!activeSubscription"
                :message="'No active subscription!'"
                :type="'is-danger'"
              />

              <div>
                <a
                  @click="$emit('goToItem', 'subscriptions')"
                  class="is-small mr-3"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </span>
                  <span>Go to subscriptions</span>
                </a>
              </div>
            </div>

            <div class="mb-6">
              <p class="title">Location activation status</p>

              <Message
                v-if="
                  !locationState.Locked &&
                  locationState.ActivationStatus === 'Activated'
                "
                :type="'is-success'"
                :message="'Location is activated'"
              />

              <Message
                v-if="
                  !locationState.Locked &&
                  locationState.ActivationStatus === 'Denied'
                "
                :type="'is-warning'"
                :message="'Location publication has been denied, check warning and notes.'"
              />

              <Message
                v-if="
                  !locationState.Locked &&
                  locationState.ActivationStatus === 'Neutral'
                "
                :message="'Location is adding data and busy setting up.'"
              />

              <Message
                v-if="
                  !locationState.Locked &&
                  locationState.ActivationStatus === 'Requested'
                "
                :type="'is-info'"
                :message="'Location has requested to be published on the channel.'"
              />

              <Message
                v-if="locationState.Locked"
                :type="'is-danger'"
                :message="'Location has been locked. Reason: ' + lockReason"
              />

              <Message
                v-if="
                  !locationState.Locked &&
                  locationState.ActivationStatus === 'Stopped'
                "
                :type="'is-dark'"
                :message="'Location has stopped and is no longer active.'"
              />

              <div>
                <a
                  v-if="locationState.ActivationStatus !== 'Stopped'"
                  @click="setShowStopLocation()"
                  class="is-small mr-3"
                >
                  <span class="icon has-text-danger">
                    <font-awesome-icon :icon="['fas', 'stop-circle']" />
                  </span>
                  <span>Stop location</span>
                </a>

                <a @click="setShowChangeStatus()" class="is-small mr-3">
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </span>
                  <span>Change status</span>
                </a>

                <a
                  v-if="!locationState.Locked"
                  @click="setShowLockLocation()"
                  class="is-small mr-3"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'lock']" />
                  </span>
                  <span>Lock location</span>
                </a>

                <a
                  v-if="locationState.Locked"
                  @click="unlockLocation()"
                  class="is-small mr-3"
                >
                  <span class="icon has-text-danger">
                    <font-awesome-icon :icon="['fas', 'lock-open']" />
                  </span>
                  <span>Unlock</span>
                </a>
              </div>
            </div>

            <div class="mb-6">
              <p class="title">Channel publication</p>

              <Message
                v-if="locationChannelState.LocationActive"
                :type="'is-success'"
                :message="'Location is published on channel'"
              />
              <Message
                v-if="!locationChannelState.LocationActive"
                :type="'is-danger'"
                :message="'Location is not published on channel'"
              />

              <div>
                <a
                  v-if="!locationChannelState.LocationActive"
                  @click="publishLocation"
                  class="is-small mr-3"
                >
                  <span class="icon has-text-success">
                    <font-awesome-icon :icon="['fas', 'check']" />
                  </span>
                  <span>Published</span>
                </a>
                <a
                  v-if="locationChannelState.LocationActive"
                  @click="setShowUnpublishLocation"
                  class="is-small mr-3"
                >
                  <span class="icon has-text-danger">
                    <font-awesome-icon :icon="['fas', 'times']" />
                  </span>
                  <span>Unpublish</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="column has-background-white-bis">
        <div class="mb-6">
          <p class="title">Location check</p>

          <LocationCheck />
        </div>

        <div class="mb-6">
          <p class="title">Notes</p>

          <Notes
            v-if="locationChannelState"
            :viewType="'small'"
            :typeId="'Location'"
            :header="'Location notes'"
            :itemId="locationId"
            :workEnvironment="'Channel'"
            :environmentId="channelId"
          />
        </div>

        <div class="mb-6">
          <p class="title">To-dos</p>

          <ToDos
            v-if="locationChannelState"
            :viewType="'small'"
            :typeId="'Location'"
            :header="'Location to-dos'"
            :itemId="locationId"
            :workEnvironment="'Channel'"
            :environmentId="channelId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import channelProvider from '@/providers/channel'
import locationProvider from '@/providers/location'
import subscriptionProvider from '@/providers/subscription'

export default {
  components: {
    LocationCheck: () => import('@/components/Locations/LocationCheck'),
    LocationData: () => import('@/components/Locations/LocationData'),
    LocationLegal: () => import('@/components/Locations/LocationLegal'),
    LocationLogo: () => import('@/components/Locations/LocationLogo'),
    LocationSocial: () => import('@/components/Locations/LocationSocial'),
    ModalChangeStatus: () => import('@/components/Locations/ModalChangeStatus'),
    ModalLockLocation: () => import('@/components/Locations/ModalLockLocation'),
    ModalStopLocation: () => import('@/components/Locations/ModalStopLocation'),
    ModalDeactivateLocation: () =>
      import('@/components/Locations/ModalDeactivateLocation'),

    Message: () => import('@/components/UI/Message'),
    Notes: () => import('@/components/Notes/Notes'),
    ToDos: () => import('@/components/ToDos/ToDos'),
  },

  props: {},

  data() {
    return {
      activeSubscription: null,
      license: null,
      licenseChecked: false,
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,

      showChangeStatus: false,
      showLockLocation: false,
      showStopLocation: false,
      showReactivateLocation: false,
      showUnpublish: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['location', 'locationState']),

    locationChannelState() {
      if (this.locationState) {
        let channel = this.locationState.Channels.find(
          (c) => c.ChannelId === this.channelId
        )
        if (channel) {
          return channel
        }
      }
      return null
    },

    agreedToLicense() {
      let agreed = false

      if (this.locationState && this.locationChannelState) {
        agreed = this.locationChannelState.AgreedToTerms
      }

      return agreed
    },

    terms() {
      let terms = null

      if (this.locationState) {
        terms = this.locationChannelState.ChannelLocationTerms
      }

      return terms
    },

    lockReason() {
      let reason = ''

      if (this.locationState && this.locationState.Locked) {
        switch (this.locationState.LockType) {
          case 1:
            reason = 'No license agreement'
            break
          case 2:
            reason = 'Unpaid invoices'
            break
          case 3:
            reason = 'Suspecious behaviour'
            break
          case 4:
            reason = 'Other'
            break
        }
      }

      return reason
    },
  },

  created() {
    this.getLocationData()
    this.getActiveChannelSubscription()
  },

  methods: {
    ...mapMutations('locationStore', ['setLocation']),
    ...mapMutations('locationStore', ['setLocationState']),

    getLocationData() {
      let self = this
      locationProvider.methods
        .getLocationById(self.locationId)
        .then((response) => {
          if (response.status === 200) {
            self.setLocation(response.data)
          }
        })
        .catch((error) => {})
        .finally(() => {})
    },

    getActiveChannelSubscription() {
      subscriptionProvider.methods
        .getSubscriptions(
          this.channelId,
          this.locationId,
          new Date().getMonth() + 1,
          new Date().getFullYear(),
          0,
          ''
        )
        .then((response) => {
          if (response.status === 200) {
            let subscriptions = response.data
            if (subscriptions.length > 0) {
              this.activeSubscription = subscriptions[0]
            }
          }
        })
        .catch((e) => {})
        .finally(() => {})
    },

    activateLocation() {
      let self = this

      locationProvider.methods
        .activateLocation(self.locationId)
        .then((response) => {
          if (response.status === 200) {
            self.locationState.ActivationStatus = 'Activated'
            self.setLocationState(this.locationState)
          }
        })
        .catch((error) => {})
        .finally(() => {})
    },

    deactivateLocation() {
      let self = this

      locationProvider.methods
        .deactivateLocation(this.locationId)
        .then((response) => {
          if (response.status === 200) {
            state.RequestActivation = 'Denied'
            self.setLocationState(state)
            self.setLocation(this.location)
          }
        })
        .catch((error) => {})
        .finally(() => {})
    },

    unlockLocation() {
      let self = this

      let state = JSON.parse(JSON.stringify(self.locationState))

      locationProvider.methods
        .unlockLocation(this.location.Id)
        .then((response) => {
          if (response.status === 200) {
            state.Locked = false
            this.setLocationState(state)
          }
        })
        .catch((e) => {})
        .finally(() => {})
    },

    publishLocation() {
      channelProvider.methods
        .activateLocationChannel(this.channelId, this.locationId)
        .then((response) => {
          if (response.status === 200) {
            let state = JSON.parse(JSON.stringify(this.locationState))
            let channelIndex = state.Channels.findIndex(
              (c) => c.ChannelId === this.channelId
            )
            if (channelIndex > -1) {
              let channel = state.Channels[channelIndex]
              channel.LocationActive = true
              Vue.set(state.Channels, channelIndex, channel)
            }
            this.setLocationState(state)
          }
        })
        .catch((err) => {})
    },

    unpublishLocation() {
      let state = JSON.parse(JSON.stringify(this.locationState))
      let channelIndex = state.Channels.findIndex(
        (c) => c.ChannelId === this.channelId
      )
      if (channelIndex > -1) {
        let channel = state.Channels[channelIndex]
        channel.LocationActive = false
        Vue.set(state.Channels, channelIndex, channel)
      }
      this.setLocationState(state)
    },

    setShowChangeStatus() {
      this.showChangeStatus = true
    },

    setShowLockLocation() {
      this.showLockLocation = true
    },

    setShowStopLocation() {
      this.showStopLocation = true
    },

    setShowUnpublishLocation() {
      this.showUnpublish = true
    },

    hideModal() {
      this.showChangeStatus = false
      this.showLockLocation = false
      this.showStopLocation = false
      this.showUnpublish = false
    },
  },
}
</script>

<style></style>
